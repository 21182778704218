
















































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import { dispatchCheckLoggedIn } from "@/store/main/actions";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import { readUserProfile } from "@/store/main/getters";
import Progress from "./Progress.vue";
import _ from "lodash";

@Component({
  components: { Header, Footer, Progress },
})
export default class ContactReview extends Vue {
  public progress = "3";
  public async mounted() {
    await dispatchCheckLoggedIn(this.$store);
  }
  get userProfile() {
    return readUserProfile(this.$store);
  }
}
